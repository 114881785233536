<template>
  <cz-dialog
    :title="$t('admin.createSuperAdminUserTitle')"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form
        class="pa-6"
        @submit.prevent="$emit('on-create', form)"
        :disabled="loading"
      >
        <cz-form-field :label="$t('common.fullName')" required>
          <validation-provider
            rules="required"
            :name="$t('common.fullName')"
            v-slot="{ errors }"
          >
            <cz-input v-model="form.fullName" :error-messages="errors" />
          </validation-provider>
        </cz-form-field>

        <cz-form-field :label="$t('common.email')" required>
          <validation-provider
            rules="required|email"
            :name="$t('common.email')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="form.email"
              :error-messages="errors"
              type="email"
            />
          </validation-provider>
        </cz-form-field>

        <cz-form-field :label="$t('common.password')" required>
          <validation-provider
            rules="required|password"
            :name="$t('common.password')"
            v-slot="{ errors }"
          >
            <cz-input v-model="form.password" :error-messages="errors" />
          </validation-provider>
        </cz-form-field>

        <v-btn
          color="primary"
          type="submit"
          class="mt-4"
          :disabled="invalid"
          large
          width="100%"
          :loading="loading"
          >{{ $t('admin.createSuperAdminUserButtonTitle') }}</v-btn
        >
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzInput, CzFormField } from '@/components';
export default {
  name: 'NewSuperAdminUserDialog',
  components: {
    CzDialog,
    CzInput,
    CzFormField
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        fullName: '',
        email: '',
        password: ''
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
